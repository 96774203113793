import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
 import logo from "../images/logo_color.png"

import tw from "twin.macro"
import SectionCard from "../components/Card/SectionCard"
import ColumnCard from "../components/Card/ColumnCard"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import FAIcon from "../components/Layout/FAIcon"
import SEO from "../components/Layout/Seo"
import LayoutPage from "../components/global/Layout"

const Button = styled.button(() => [
  `
@media (min-width: 768px) {
	max-width: 300px;
}
@media (min-width: 1024px) {
	max-width: 300px;
}
width: 100%;
margin-top: 1.25rem;
margin-left: auto;
margin-right: auto;
color: white;
background-color: #646fff;
transition: background-color 0.7s ease;
padding-top: 0.25rem;
padding-bottom: 0.25rem;

&:hover {
	background-color: #8d94f4;
}
-webkit-box-shadow: 0 0 10px 1px #646fff;
box-shadow: 0 0 10px 1px #646fff;
`,
  tw`px-8 py-3 text-base rounded-ultra `,
])


const countries = [{
  label: "Argentina",
  iconName: "ar",
  to: "/ar"
},
{
  label: "México",
  iconName: "mx",
  to: "/mx"
},
{
  label: "Uruguay",
  iconName: "uy",
  to: "/uy"
}
]

const CountryButton = ({ iconName, label, to }: { iconName: any, label: any, to: any }) => {
  return <Link to={to}>
    <div
      className=" light-c "
      css={tw`cursor-pointer flex flex-row  space-x-2 bg-autogestiones-100 bg-opacity-25 hover:bg-opacity-50 py-1 px-2 rounded-lg  items-center `}>
      <img
        alt={"Gestionar en " + label}
        src={`/flags/${iconName}.svg`} style={{
          width: 28,
          height: 28
        }} />
      <div css={tw`text-white text-lg flex w-full items-center justify-between lg:block`}>
        {label} <i css={tw`lg:hidden`} className="fa-chevron-right  fa-solid"></i>
      </div>
    </div>
  </Link>
}
const IndexPage = () => {



  return (
    <>

      <div css={tw`   h-screen w-full flex justify-center items-center   left-0 right-0`}>
        <div css={tw`flex flex-col lg:flex-row   items-center space-y-4 lg:space-y-0 space-x-0 lg:space-x-8 `}>
          <div css={tw`   flex  lg:w-auto w-7/12  `}  >
            <img
              alt="Logo de Autogestiones"
              src="/logo/autogestiones.png" css={tw`w-full flex  `} />
          </div>
          <div css={tw`grid gap-4 lg:px-0 px-8 lg:gap-3 lg:pt-0 pt-4 w-full lg:w-auto `}>
            {countries.map((country) => <CountryButton {...country} />)}
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage

export const Head = () =>
  <SEO

    description="Aumenta tus ventas en línea con una solución de integración de comercio electrónico completa. Centraliza la gestión, amplía tus canales de venta y maximiza la eficiencia de tu negocio."
    title="Autogestiones" />
